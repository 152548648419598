import React from 'react';
import './Home.css';
import { Link } from 'react-scroll'; // Import Link from react-scroll

export default function Home() {

    return (
        <>
        <section id="home">
            <div className="banner_image"></div>
            <div className="container">
                <div className="banner_outer">
                    <div className="col"> 
                        <h3 className="title">
                            LET US BUILD YOU YOUR <span>DREAM PC</span>
                        </h3>
                        <p>We believe technology is not something you purchase, but something that you have the vision and ability to create. We are committed to providing the finest design, engineering, and production of custom high-performance computer systems.</p>
                        <div className="btn_wrapper">
                            <Link to="contact" spy={true} smooth={true} className="btn">
                                Get Started
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
