import React, { useState } from 'react';
import './Contact.css';
import contactPic from '../../assets/contact/contact.jpg';

const Contact = () => {
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errors, setErrors] = useState({ email: '', phone: '' });

  const validateEmail = (email) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^(\+?\d{1,2})?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
    return phoneRegex.test(phone);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    const emailValid = validateEmail(data.email);
    const phoneValid = validatePhone(data.phone);

    if (!emailValid || !phoneValid) {
      setErrors({
        email: emailValid ? '' : 'Please enter a valid email address.',
        phone: phoneValid ? '' : 'Please enter a valid phone number.'
      });
      return;
    }

    setErrors({ email: '', phone: '' }); // Clear errors if valid

    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.success) {
        setConfirmationMessage('Message sent! We will get back to you shortly!');
      } else {
        setConfirmationMessage('Failed to send the message, please email george@pcsbygeorge.com for further assistance.');
      }
      setShowConfirmation(true);
    } catch (error) {
      setConfirmationMessage('Failed to send the message, please email george@pcsbygeorge.com for further assistance.');
      setShowConfirmation(true);
    }

    e.target.reset(); // Reset the form after submission
  };

  const closeConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <div id="contact">
      <div className="container">
        <h2>Contact Us</h2>
        <div className="contact_wrapper">
          <div className="contact_col">
            <div className="contact_image">
              <img src={contactPic} alt="Contact" />
            </div>
          </div>
          <div className="contact_col">
            <form onSubmit={sendEmail}>
              <div className="input_wrapper">
                <input 
                  type="text" 
                  name="name" 
                  className="form-control" 
                  placeholder="Your Name" 
                  required 
                />
              </div>
              <div className="input_wrapper">
                <input 
                  type="email" 
                  name="email" 
                  className="form-control" 
                  placeholder="Your Email" 
                  required 
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
              <div className="input_wrapper">
                <input 
                  type="tel" 
                  name="phone" 
                  className="form-control" 
                  placeholder="Your Phone Number" 
                  required 
                />
                {errors.phone && <p className="error-message">{errors.phone}</p>}
              </div>
              <div className="input_wrapper">
                <textarea 
                  name="message" 
                  className="form-control" 
                  placeholder="Your Message" 
                  required 
                />
              </div>
              <div className="btn_wrapper">
                <button type="submit" className="btn">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <div className="confirmation-dialog">
          <div className="confirmation-content">
            <p>{confirmationMessage}</p>
            <button className="btn" onClick={closeConfirmation}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
