import React from 'react';
import './Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';

export default function Services() {

    // Icons
    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />
    return (
    <>
        <div id="services">
            <div className="container">
                <div className="title_headling">
                    <h3>Discover Our Premier Services</h3>
                    <p>Elevate your tech experience with our curated suite of services, designed to empower your digital lifestyle and business needs. Join the ranks of our satisfied clientele and lead the charge in innovation and performance.</p>
                </div>
                <div className="service_wrapper">
                    <div className="service_box">
                        <div className="service_icon blue_icon">{faLaptopCodeIcon}</div>
                        <h4 className="number">01</h4>
                        <div className="service_content">
                            <h5>Custom PC Build</h5>
                            <p>Customized PCs for every role, from gamers to creators. Precision-engineered to power your passion and professional needs.</p>
                            <Link to="contact" spy={true} smooth={true} duration={500} className="read services-link">
                                Learn More
                            </Link>

                        </div>
                    </div> 
                    <div className="service_box">
                        <div className="service_icon">{faChartBarIcon}</div>
                        <h4 className="number">02</h4>
                        <div className="service_content">
                            <h5>Hardware Optimization</h5>
                            <p>Maximize your PC's performance with our custom tuning. Experience enhanced speed and reliability in every component.</p>
                            <Link to="contact" spy={true} smooth={true} duration={500} className="read services-link">
                                Learn More
                            </Link>                        </div>
                    </div>
                    <div className="service_box">
                        <div className="service_icon green_icon">{faCopyIcon}</div>
                        <h4 className="number">03</h4>
                        <div className="service_content">
                            <h5>Software Optimization</h5>
                            <p>Enhance your Windows experience with tailored optimization. We fine-tune applications and games for optimal performance and stability.</p>                    
                            <Link to="contact" spy={true} smooth={true} duration={500} className="read services-link">
                                Learn More
                            </Link>                        </div>
                    </div>
                    <div className="service_box">
                        <div className="service_icon">{faMarkerIcon}</div>
                        <h4 className="number">04</h4>
                        <div className="service_content">
                            <h5>Software & Web Development</h5>
                            <p>Tailored digital solutions, from sleek websites to robust software. Elevate your online and operational efficiency.</p>
                            <Link to="contact" spy={true} smooth={true} duration={500} className="read services-link">
                                Learn More
                            </Link>                        </div>
                    </div>
                    <div className="service_box">
                        <div className="service_icon green_icon">{faUserGearIcon}</div>
                        <h4 className="number">05</h4>
                        <div className="service_content">
                            <h5>Process Automation</h5>
                            <p>Streamline your workflow with AI automation. Reduce manual tasks and boost productivity with intelligent solutions.</p>
                            <Link to="contact" spy={true} smooth={true} duration={500} className="read services-link">
                                Learn More
                            </Link>                        </div>
                    </div>
                    <div className="service_box">
                        <div className="service_icon blue_icon">{faCoinsIcon}</div>
                        <h4 className="number">06</h4>
                        <div className="service_content">
                            <h5>Consulting Services</h5>
                            <p>Expert guidance for tech transitions. From system upgrades to strategic planning, we'll navigate the tech landscape with you.</p>
                            <Link to="contact" spy={true} smooth={true} duration={500} className="read services-link">
                                Learn More
                            </Link>                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
