import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css';

const Carousel = () => {
  const [reviews, setReviews] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detect screen width to check if the user is on a mobile device
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    handleResize(); // Check screen width on initial load
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetch('/reviews')
      .then(response => response.json())
      .then(data => setReviews(data))
      .catch(error => console.error('Error fetching reviews:', error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {'★'.repeat(fullStars)}
        {halfStars ? '☆' : ''}
        {'☆'.repeat(emptyStars)}
      </>
    );
  };

  return (
    <div id="reviews" className="carousel-container">
      <h2 className="carousel-title">What Our Clients Say</h2>
      <Slider {...settings}>
        {reviews.length > 0 ? (
          reviews.map((review, index) => (
            <div key={index} className="carousel-slide">
              <div className="review-content">
                <p className="review-text">"{review.text}"</p>
                <div className="review-author-section">
                  {review.profile_photo_url && (
                    <img 
                      src={review.profile_photo_url} 
                      alt={`${review.author_name}'s profile`} 
                      className="reviewer-photo"
                    />
                  )}
                  <div className="review-author-info">
                    <p className="review-author">- {review.author_name}</p>
                    <div className="review-rating">
                      {renderStars(review.rating)}
                    </div>
                  </div>
                </div>
                {review.photos && review.photos.length > 0 && (
                  <div className="review-photos">
                    {review.photos.map((photo, i) => (
                      <img 
                        key={i} 
                        src={photo} 
                        alt={`Reviewer profile ${i + 1}`} 
                        className="review-image"
                      />
                    ))}
                  </div>
                )}
                <a 
                  href={isMobile ? "https://maps.app.goo.gl/sA5bzzBYFYdRkGnq6?g_st=com.google.maps.preview.copy" : review.business_review_url}
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="review-link"
                >
                  Read Full Review
                </a>
              </div>
            </div>
          ))
        ) : (
          <div className="carousel-slide">
            <p>No reviews available at the moment.</p>
          </div>
        )}
      </Slider>
    </div>
  );
};

export default Carousel;
