import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-scroll';
import Logo from '../../assets/logo/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Navbar() {
    const [sidenav, setSidenav] = useState(false);
    const [sticky, setSticky] = useState(false);

    const menuIcon = <FontAwesomeIcon icon={faBars} />;
    const closeIcon = <FontAwesomeIcon icon={faTimes} />;

    const sidenavShow = () => {
        setSidenav(!sidenav);
        document.body.classList.toggle('menu-open', !sidenav);
    }

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 20);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <header id="site_header" className={`${sticky ? "sticky" : ''}`}>
                <div className="container">
                    <nav className="navbar" id="Navbar">
                        <div className="navbar_brand">
                            <a href="/">
                                <img src={Logo} alt="Logo" />
                            </a>
                        </div>
                        <div className="navbar_toggler" onClick={sidenavShow}>
                            {menuIcon}
                        </div>
                        <div className={`menu_items ${sidenav ? 'active' : ''}`}>
                            <span className="close-menu" onClick={sidenavShow}>{closeIcon}</span>
                            <ul>
                                <li>
                                    <Link activeClass="active" to="home" spy={true} smooth={true} onClick={sidenavShow}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="reviews" spy={true} smooth={true} onClick={sidenavShow}>
                                        Reviews
                                    </Link>
                                </li>
                                <li>
                                    <Link to="services" spy={true} smooth={true} onClick={sidenavShow}>
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="contact" spy={true} smooth={true} onClick={sidenavShow}>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}
